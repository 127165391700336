import { useRouter } from 'next/router';

import { Page } from 'ui/components/Page';
import { PageHead } from 'ui/components/PageHead';
import { SanityContentSlot } from 'ui/components/SanityContentSlot';
import { useGroqQuery } from 'hooks/useGroqQuery';
import { MarketingPageQuery } from 'groq/pages/MarketingPage';
import { getUrlByCategory } from 'utils/getUrlByCategory';

export const MarketingPage = () => {
  const router = useRouter();
  const category = router.query.category as string[];
  const url = getUrlByCategory(category);

  const [marketingPage] = useGroqQuery({
    operationName: 'MarketingPage',
    query: MarketingPageQuery,
    variables: { url },
  });
  const categoryContent = marketingPage.data?.content;
  const seo = categoryContent?.seo;

  return (
    <>
      <PageHead
        noIndex={seo?.noIndex}
        title={
          seo?.pageTitle && seo?.pageTitle.length > 0
            ? seo?.pageTitle
            : seo?.pageTitles
        }
        description={
          seo?.pageDescription && seo?.pageDescription.length > 0
            ? seo?.pageDescription
            : seo?.pageDescriptions
        }
      />
      <Page
        pageViewPageType="mlp"
        query={{
          ...marketingPage,
          fetching: marketingPage.fetching || marketingPage.stale,
        }}
      >
        <SanityContentSlot
          items={categoryContent?.content}
          slotId="slots"
          documentId={categoryContent?._id}
          fullscreen
          headersContent={categoryContent?.headersContent}
        />
      </Page>
    </>
  );
};
