import { gql, useQuery, UseQueryState } from 'urql';

import { CATEGORY_FRAGMENT } from 'gql/fragments/category';
import {
  PAGINATED_OUTPUT_METADATA_FRAGMENT,
  PAGINATED_OUTPUT_NODES_FRAGMENT,
} from 'gql/fragments/products';
import { PreviewInput, ProductsInput, Category } from '__generated__/graphql';
import {
  MANDATORY_MASTER_FIELDS,
  MANDATORY_VARIANT_FIELDS,
} from 'gql/fragments/product';

import { CommonQueryOptions, commonQueryVariables } from './products';

export const CATEGORY_PLP_QUERY = gql`
  query CategoryPLP(
    $url: String!
    $isDraft: String
    $sort: String
    $filters: [FilterInputOption!]!
    $expansions: [ProductSearchExpansion!]
    $includeCategoryMetadata: Boolean = true
    $offset: Int!
    $limit: Int!
    $preview: PreviewInput
  ) {
    categoryByUrl(url: $url, preview: $preview) {
      id
      name
      url
      ...category @include(if: $includeCategoryMetadata)
      description @include(if: $includeCategoryMetadata)
      products(
        input: {
          sort: $sort
          filters: $filters
          limit: $limit
          offset: $offset
          expansions: $expansions
          includeCategoryMetadata: $includeCategoryMetadata
          isDraft: $isDraft
        }
      ) {
        ...PaginatedOutputNodesFragment
        ...PaginatedOutputMetadataFragment
          @include(if: $includeCategoryMetadata)
      }
      seo @include(if: $includeCategoryMetadata) {
        pageTitle
        pageDescription
      }
      parentCategory {
        ...category
        parentCategory {
          ...category
          parentCategory {
            ...category
            parentCategory {
              ...category
            }
          }
        }
      }
    }
  }

  ${CATEGORY_FRAGMENT}
  ${PAGINATED_OUTPUT_NODES_FRAGMENT}
  ${PAGINATED_OUTPUT_METADATA_FRAGMENT}
  ${MANDATORY_MASTER_FIELDS}
  ${MANDATORY_VARIANT_FIELDS}
`;

export type CategoryPLPQueryOptions = CommonQueryOptions & {
  url: string;
  src?: string;
  isDraft?: string;
  preview?: PreviewInput;
};

export type CategoryPLPQueryVariables = {
  url: string;
  src?: string;
  isDraft?: string;
  preview?: PreviewInput;
} & ProductsInput;

export type CategoryPLPQueryResult = { categoryByUrl: Category | null };

export type CategoryPLPQueryState = UseQueryState<
  CategoryPLPQueryResult,
  CategoryPLPQueryVariables
>;

export const useCategoryPLPQuery = (options: CategoryPLPQueryOptions) => {
  const query = useQuery<CategoryPLPQueryResult, CategoryPLPQueryVariables>({
    query: CATEGORY_PLP_QUERY,
    pause: options.pause,
    variables: {
      ...commonQueryVariables(options),
      url: options.url,
      src: options.src,
      preview: options.preview,
      includeCategoryMetadata: options.includeMetadata,
    },
  });
  return query;
};
