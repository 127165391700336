export const filterActivated = queryParams => {
  return (
    !!queryParams.pref_color ||
    !!queryParams.pref_fit ||
    !!queryParams.pref_maxPrice ||
    !!queryParams.pref_minPrice ||
    !!queryParams.pref_size ||
    !!queryParams.pref_style ||
    !!queryParams.sort
  );
};
