import { useEffect } from 'react';

import {
  AnalyticsEvents,
  AnalyticsEventValues,
  event,
  transformListProductToAnalyticsProduct,
} from 'utils/analytics';
import { isNotNullish } from 'utils/isNotNullish';
import { FilterInputOption, ProductSearchHit } from '__generated__/graphql';

import { useAnalyticsEventAfterPageviewEventHasFired } from './useAnalyticsEventAfterPageviewEventHasFired';
import { usePageEventsContext } from './usePageEventsContext';
import { useSiteConfig } from './useSiteConfig';

export type ProductListAnalyticsOptions = {
  filters?: FilterInputOption[];
  sort?: string | null;
  offset?: number;
  products?: ProductSearchHit[] | null;
  list?: string | null;
  categoryId?: string | null;
  itemListId?: string;
  itemListName?: string;
  itemIdEp?: string;
  itemNameEp?: string;
  items?: any[];
  search?: string | null;
};

export const useProductListAnalytics = ({
  filters = [],
  sort,
  offset = 0,
  products,
  list,
  categoryId,
  itemListId,
  itemListName,
  itemIdEp,
  itemNameEp,
  items,
  search,
}: ProductListAnalyticsOptions) => {
  const hasFilters = !!sort || filters.length > 0;
  const serializedFilters = JSON.stringify({ filters, sort });
  const PRODUCT_LIST_UPDATED_EVENT_NAME = `${AnalyticsEvents.PRODUCT_LIST_UPDATED}_${offset}`;
  const PRODUCT_LIST_REFRESHED_EVENT_NAME = `${AnalyticsEvents.PRODUCT_LIST_REFRESHED}_${serializedFilters}`;
  const { currency } = useSiteConfig();
  const { setEventFired, setEventNotFired, eventHasFired } =
    usePageEventsContext();

  const clearPageUpdatedEvents = () => {
    setEventNotFired(new RegExp(`${AnalyticsEvents.PRODUCT_LIST_UPDATED}_.*`));
  };

  const fireEvent = (eventName: AnalyticsEventValues) => {
    event(eventName, {
      ecommerce: {
        currencyCode: currency.code,
        impressions: (products ?? [])
          .map((product, idx) =>
            transformListProductToAnalyticsProduct(
              product,
              offset + idx,
              list,
              categoryId
            )
          )
          .filter(isNotNullish),
      },
    });

    // NOTE: only fire event if there are items to display
    if (items && items.length > 0) {
      event(AnalyticsEvents.GA4EC_ItemListView, {
        event_name: AnalyticsEvents.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_id: search ? search : itemListId,
          item_list_name: search ? search : itemListName,
          item_id_ep: itemIdEp,
          item_name_ep: itemNameEp,
          items: items,
        },
      });
    }
  };

  // the logic here may seem convoluted, but the expectation is that:
  //
  // * AnalyticsEvents.PRODUCT_LIST_LOADED is fired when a PLP is first loaded (but only after the pageview event has fired)
  // * AnalyticsEvents.PRODUCT_LIST_UPDATED is fired when a new page of products is loaded
  // * AnalyticsEvents.PRODUCT_LIST_REFRESHED is fired when the filters or sort order are changed

  useAnalyticsEventAfterPageviewEventHasFired(() => {
    if (!products) return;
    if (offset === 0) {
      // page one of results
      if (!eventHasFired(AnalyticsEvents.PRODUCT_LIST_LOADED)) {
        // this is the first load of the PLP
        fireEvent(AnalyticsEvents.PRODUCT_LIST_LOADED);
        setEventFired(AnalyticsEvents.PRODUCT_LIST_LOADED);
      }
    }
  }, [products, offset]);

  useEffect(() => {
    if (!products) return;
    if (
      offset === 0 &&
      hasFilters &&
      !eventHasFired(PRODUCT_LIST_REFRESHED_EVENT_NAME)
    ) {
      // this is a reload of the PLP with filters that have not been chosen before
      fireEvent(AnalyticsEvents.PRODUCT_LIST_REFRESHED);
      setEventFired(PRODUCT_LIST_REFRESHED_EVENT_NAME);
      clearPageUpdatedEvents();
    } else if (offset > 0 && !eventHasFired(PRODUCT_LIST_UPDATED_EVENT_NAME)) {
      // this is page two or greater, meaning loading more products
      fireEvent(AnalyticsEvents.PRODUCT_LIST_UPDATED);
      setEventFired(PRODUCT_LIST_UPDATED_EVENT_NAME);
    }
  }, [products, offset, serializedFilters]); // eslint-disable-line react-hooks/exhaustive-deps
};
