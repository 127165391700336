import * as React from 'react';
import { css, tw } from 'twind/css';

import { SafeHtml } from 'ui/elements/SafeHtml';
import { useFeature } from 'hooks/useFeature';

const linkStyles = css`
  & a {
    @apply underline font-bold;
  }
`;
const paragraphStyles = css`
  & p {
    @apply inline;
  }
`;

export type ReadMoreProps = {
  className?: string;
  innerHtmlString: string;
};

export const InlineReadMore: React.FC<ReadMoreProps> = ({
  className,
  innerHtmlString,
}) => {
  const isDividerEnabled = useFeature('USE_SFCC_DIVIDER_FOR_SEO_DESCRIPTION');

  const containerClassName = tw(
    className,
    paragraphStyles,
    isDividerEnabled && linkStyles
  );

  return (
    <div className={containerClassName}>
      <SafeHtml className="inline" html={innerHtmlString} />
    </div>
  );
};
