import { tw } from 'twind';
import { useRef } from 'react';

import { Ga4Data } from 'hooks/usePromotionSelect';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { usePromotionView } from 'hooks/usePromotionView';
import { Link } from 'ui/elements/Link';
import { CallToActions } from 'ui/content/CallToActions';
import { Markdown } from 'ui/elements/Markdown';
import { VideoOrImage } from 'ui/components/VideoOrImage';
import { PromoTileDocumentType } from 'groq/documents/PromoTileDocument';

const flexFromVerticalAlignment = alignment =>
  ({ top: 'start', middle: 'center', bottom: 'end' }[alignment]);

export const PromoTile: React.FC<PromoTileDocumentType> = props => {
  const textColor = props.textColor || '#000000';
  const bgColor = props.backgroundColor || 'transparent';
  const promoTileRef = useRef(null);

  const { ref, width } = useResizeObserver(promoTileRef);
  const fontSize = tw(
    width <= 280 && 'text-xl',
    width > 280 && width <= 360 && 'text-2xl',
    width > 360 && width < 640 && 'text-3xl',
    width >= 640 && 'text-4xl'
  );
  const { additionalCtaClass } = useSiteConfig();

  const promotion_name = props.header || props?.cta?.title || '';

  const gaTrackData = {
    id: props.puid?.current || '',
    name: promotion_name,
    creative: 'promo-tile',
    campaign: props.puid?.current || '',
    position: '',
  };

  const promotion_id = props.id;
  const creative_name = props._type || 'PromoTile';

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id,
    creative_name,
    cta_links: props.cta ? [props.cta.link] : [],
  };

  const { promotionTrackerPosition, ga4PromotionTrackerPosition } =
    usePromotionView(ref, extendedGaTrackData);

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: props.cta ? props.cta.link : '',
    cta_click: undefined,
  };

  return (
    <div
      ref={promoTileRef}
      className={tw(
        `bg-[${bgColor}]`,
        `relative flex flex-col flex-none w-full h-full`,
        `justify-${flexFromVerticalAlignment(props.verticalAlignment)}`
      )}
      data-test-id="stylitics-promo-tile"
    >
      <div
        className={tw(
          !props.contentBelow
            ? 'absolute inset-0'
            : 'relative w-full h-full flex-grow'
        )}
      >
        <VideoOrImage
          image={props.backgroundImage}
          header={props.header}
          video={props.video}
          mobileImage={props.mobileBackgroundImage}
        />
      </div>
      {props.cta && (
        <Link
          query={props.cta.query}
          newtab={!!props.cta.newtab}
          href={props.cta.link}
          className="absolute inset-0 h-full w-full"
          aria-hidden
          tabIndex={-1}
          gaBannerData={gaTrackData}
          ga4Data={ga4Data}
        />
      )}
      {(props.header || props.copy || props.cta) && (
        <div
          className={tw(
            `relative p-6 text-center w-full flex flex-col items-center text-[${textColor}]`,
            props.contentBelow && 'mt-4'
          )}
        >
          <div className={fontSize}>
            {props.header && (
              <h3 className={`font-bold text-[1em] leading-none`}>
                <Markdown content={props.header} />
              </h3>
            )}
            {props.copy && (
              <p className="mt-3 font-bold text-[0.62em] leading-snug">
                <Markdown content={props.copy} />
              </p>
            )}
          </div>
          {props.cta && props.cta.title && (
            <div className="mt-3">
              <CallToActions
                ctas={[props.cta]}
                className={additionalCtaClass ?? ''}
                gaBannerData={gaTrackData}
                ga4Data={{
                  ...ga4Data,
                  cta_click: props.cta.title || '',
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
