import { useRouter } from 'next/router';

import { Hreflangs } from 'ui/components/Hreflangs';
import { availablePages } from 'utils/hardcodedHreflangs';
import { Page } from 'ui/components/Page';
import { PageHead } from 'ui/components/PageHead';
import { SanityContentSlot } from 'ui/components/SanityContentSlot';
import { useGroqQuery } from 'hooks/useGroqQuery';
import { CategoryPageQuery } from 'groq/pages/CategoryPage';
import { useDynamicYieldContext } from 'hooks/useDynamicYieldContext';
import { dyContext } from 'utils/dynamicYield';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getUrlByCategory } from 'utils/getUrlByCategory';

export const CategoryPage = () => {
  const router = useRouter();
  const {
    staticFeatures: { setDyLanguageInEnglish },
  } = useSiteConfig();

  const category = router.query.category as string[];
  const url = getUrlByCategory(category);

  const [categoryPage] = useGroqQuery({
    operationName: 'CategoryPage',
    query: CategoryPageQuery,
    variables: { url },
  });

  const categoryId = categoryPage.data?.content?._id;

  const categoryGeneralId = categoryPage.data?.content?.id;
  const parsedCategory =
    setDyLanguageInEnglish && categoryId && categoryId.includes('_')
      ? categoryId.split('_').slice(1)
      : category;

  useDynamicYieldContext({
    type: dyContext.CATEGORY,
    data: parsedCategory,
    pause: !parsedCategory,
  });

  const categoryContent = categoryPage.data?.content;

  const seo = {
    ...categoryContent?.seo,
    noIndex: categoryContent?.categoryPageSEO?.noIndex,
  };
  const title = categoryContent?.title;

  return (
    <>
      <PageHead
        title={seo?.pageTitle}
        description={seo?.pageDescription ?? ''}
        noIndex={seo?.noIndex}
        openGraphDescription={seo?.openGraphDescription ?? ''}
        openGraphImageUrl={seo?.openGraphImageUrl ?? ''}
        openGraphTitle={seo?.openGraphTitle ?? ''}
        openGraphType={seo?.openGraphType ?? ''}
        openGraphVideoUrl={seo?.openGraphVideoUrl ?? ''}
        twitterDescription={seo?.twitterDescription ?? ''}
        twitterImageUrl={seo?.twitterImageUrl ?? ''}
        twitterTitle={seo?.twitterTitle ?? ''}
        noHreflangs={availablePages.includes(categoryGeneralId || '')}
      />
      <Hreflangs id={categoryGeneralId} />
      <Page
        pageViewPageType="clp"
        query={{
          ...categoryPage,
          fetching: categoryPage.fetching || categoryPage.stale,
        }}
        bloomreachData={{
          cat: title as string,
          cat_id: categoryContent?._id,
        }}
      >
        <SanityContentSlot
          items={categoryContent?.content}
          slotId="slots"
          documentId={categoryContent?._id}
          fullscreen
          headersContent={categoryContent?.headersContent}
        />
      </Page>
    </>
  );
};
