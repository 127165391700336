import { useEffect } from 'react';

import { usePageEventsContext } from './usePageEventsContext';

export const useAnalyticsEventAfterPageviewEventHasFired = (
  event: () => void,
  deps: any[]
) => {
  const { pageviewEventHasFired } = usePageEventsContext();

  useEffect(() => {
    if (pageviewEventHasFired) event();
  }, [pageviewEventHasFired, event, deps]);
};
