import { NextUrqlPageContext } from 'next-urql';

import { groqPreviewInput } from 'hooks/useGroqQuery';

/**
 *
 * This function generates a formatted input object for content groq queries, including the query string, source, parameters, and preview status.
 *
 */

export const getContentInputQuery = ({
  query,
  context,
  params,
}: {
  query: string;
  context: NextUrqlPageContext;
  params?: Record<string, string>;
}) => {
  return {
    input: {
      query: query.replace(/[\n]+/g, '').replace(/\s\s+/g, ''),
      src: context.query.src as string,
      params,
      draft: context.query.draft as string,
      preview: groqPreviewInput(context.query),
    },
  };
};
