import { NextRouter, useRouter } from 'next/router';
import { Client } from 'urql';
import type { NextPage } from 'next';
import { NextUrqlPageContext } from 'next-urql';

import { isServer } from 'utils/constants';
import {
  CONTENT_QUERY,
  groqPreviewInput,
  useGroqQuery,
} from 'hooks/useGroqQuery';
import { NoSearchResults } from 'ui/components/NoSearchResults';
import { MarketingPage } from 'ui/pages/MarketingPage';
import { CategoryPage } from 'ui/pages/CategoryPage';
import { ProductListingPage } from 'ui/pages/ProductListingPage';
import { PageTypeQuery } from 'groq/queries/PageType';
import { isContentOnline } from 'utils/isContentOnline';
import { PageLoadingSpinner } from 'ui/components/Page';
import {
  DefaultProductListingPageQuery,
  TopContentSlotPLPQuery,
  BottomContentSlotPLPQuery,
} from 'groq/pages/ProductListingPage';
import {
  CATEGORY_PLP_QUERY,
  CategoryPLPQueryResult,
  CategoryPLPQueryVariables,
} from 'gql/queries/plp';
import { getPreviewInput } from 'utils/getPreviewInput';
import {
  MainFooterDocument,
  MainFooterQuery,
  MainFooterQueryVariables,
} from '__generated__/graphql';
import { commonQueryVariables } from 'gql/queries/products';
import { GlobalPromoBannerDocument } from 'groq/documents/GlobalPromoBannerDocument';
import { SecondGlobalBannerDocument } from 'groq/documents/SecondGlobalBannerDocument';
import { getContentInputQuery } from 'utils/getContentInputQuery';
import { getUrlByCategory } from 'utils/getUrlByCategory';
import {
  createCategoryPageBreadcrumbLinks,
  fetchBreadcrumbRedirectPromises,
} from 'utils/breadcrumbRedirects';

// This is a catch all entry point for all pages with a URL that
// doesn't match any other page type. It will check to see if there
// is a page type for the URL and if so render that page type.

const Page: NextPage = () => {
  const router = useRouter();
  const category = router.query.category as string[];
  const url = getUrlByCategory(category);

  const [pageType] = useGroqQuery({
    operationName: 'PageType',
    query: PageTypeQuery,
    variables: { url },
  });

  const pageTypes = pageType.data?.content || {};

  // When choosing which page type to render for a given URL we need to check documents
  // in order of priority. The first document that has an _id and is online will be rendered.
  const onlinePage = ['MarketingPage', 'CategoryPage', 'category']
    .map(type => pageTypes[type])
    .filter(doc => doc?._id)
    .find(doc => isContentOnline(doc, groqPreviewInput(router.query)));

  if (pageType.fetching) return <PageLoadingSpinner />;

  switch (onlinePage?._type) {
    case 'MarketingPage':
      return <MarketingPage />;
    case 'CategoryPage':
      return <CategoryPage />;
    case 'category':
      return <ProductListingPage />;
    default:
      return <NoSearchResults />;
  }
};

// Adding this excludes the following code from the client bundle however
// all static imports that the below code depends on will still be included
if (isServer) {
  Page.getInitialProps = async (appContext: NextUrqlPageContext) => {
    const context = appContext;
    // The servers request and response objects which should always
    // exist because we only run getInitialProps on the server
    const res = context.res!;
    // hack to get urqlClient working because urqlClient is typed as being under appContext,
    // but it's actually under appContext.ctx
    const client = (context as any).urqlClient as Client;
    // If this is a request for a category page then go and do the check to
    // see if there is either CLP or PLP content for that category (URL)

    const pageType = await client
      .query(CONTENT_QUERY('PageType'), {
        input: {
          query: PageTypeQuery.replace(/[\n]+/g, '').replace(/\s\s+/g, ''),
          params: {
            url: `/${(context.query.category as string[]).join('/')}`,
          },
          src: context.query.src as string,
          draft: context.query.draft as string,
          preview: groqPreviewInput(context.query),
        },
      })
      .toPromise();

    const pageTypes = pageType.data?.content || {};

    const onlinePage = ['MarketingPage', 'CategoryPage', 'category']
      .map(type => pageTypes[type])
      .filter(doc => doc?._id)
      .find(doc => isContentOnline(doc, groqPreviewInput(context.query)));

    if (context.query.offset && !context.query.page) {
      const searchParams = new URLSearchParams(
        context.query as unknown as string
      );
      searchParams.delete('offset');
      searchParams.delete('country');
      searchParams.delete('category');
      searchParams.delete('language');

      const stringifiedQueryParams = searchParams.toString();
      res.writeHead(308, {
        Location: `${context?.asPath?.split('?')[0]}${
          stringifiedQueryParams && '?'
        }${stringifiedQueryParams}`,
      });
      res.end();
      return {};
    }

    if (!onlinePage) res.statusCode = 404;

    if (onlinePage?._type === 'category') {
      const desiredCategory = context.query.category as string[];
      const categoryUrl = `/${desiredCategory?.join('/')}`;

      const defaultProductListingPageQuery = context.urqlClient.query(
        CONTENT_QUERY('DefaultProductListingPage'),
        getContentInputQuery({
          query: DefaultProductListingPageQuery,
          context,
          params: {
            url: categoryUrl,
          },
        })
      );

      const topContentSlotPLPQuery = context.urqlClient.query(
        CONTENT_QUERY('TopContentSlotPLP'),
        getContentInputQuery({
          query: TopContentSlotPLPQuery,
          context,
          params: {
            url: categoryUrl,
          },
        })
      );

      const bottomContentSlotPLPQuery = context.urqlClient.query(
        CONTENT_QUERY('BottomContentSlotPLP'),
        getContentInputQuery({
          query: BottomContentSlotPLPQuery,
          context,
          params: {
            url: categoryUrl,
          },
        })
      );

      const globalPromoBannerQuery = context.urqlClient.query(
        CONTENT_QUERY('GlobalPromoBanner'),
        getContentInputQuery({
          query: GlobalPromoBannerDocument,
          context,
        })
      );

      const secondGlobalBannerQuery = context.urqlClient.query(
        CONTENT_QUERY('SecondGlobalBanner'),
        getContentInputQuery({
          query: SecondGlobalBannerDocument,
          context,
        })
      );

      const mainFooterQuery = context.urqlClient.query<
        MainFooterQuery,
        MainFooterQueryVariables
      >(MainFooterDocument, { isDraft: context.query.draft as string });

      const categoryPLPQuery = context.urqlClient.query<
        CategoryPLPQueryResult,
        CategoryPLPQueryVariables
      >(CATEGORY_PLP_QUERY, {
        url: categoryUrl,
        src: context.query.src as string,
        preview: getPreviewInput(context.query),
        includeCategoryMetadata: true,
        ...commonQueryVariables({
          router: {
            query: context.query,
          } as NextRouter,
          offset: 0,
        }),
      });

      const promises = [
        defaultProductListingPageQuery,
        topContentSlotPLPQuery,
        bottomContentSlotPLPQuery,
        categoryPLPQuery,
        globalPromoBannerQuery,
        secondGlobalBannerQuery,
        mainFooterQuery,
      ];

      const categoryPLPResult = await categoryPLPQuery.toPromise();
      const currentCategory = categoryPLPResult.data?.categoryByUrl;

      // If we have a category, pre-load URL redirect queries for breadcrumb links
      if (currentCategory) {
        const breadcrumbLinks =
          createCategoryPageBreadcrumbLinks(currentCategory);
        const redirectPromises = fetchBreadcrumbRedirectPromises(
          context.urqlClient,
          breadcrumbLinks
        );

        await Promise.all([...promises, ...redirectPromises]);
      } else {
        await Promise.all(promises);
      }
    }

    // These props get inherited by any getInitialProps further up the render tree
    // in this case that is _app but ultimately there are no props to pass up
    // return an object that a non-empty to prevent "returned an empty object" error in logs
    return { emptyProps: true };
  };
}

export default Page;
