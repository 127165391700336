import { gql } from 'urql';

export const CATEGORY_FRAGMENT = gql`
  fragment category on Category {
    id
    name
    title
    url
    visible
  }
`;
