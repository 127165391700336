import { Maybe } from 'utils/types';
import { ProductListingPage } from '__generated__/graphql-sanity';
import { SlotsGroqQuery } from 'groq/ContentSlots';
import { TileWithPositionObject } from 'groq/objects/TileWithPosition';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { QueryByLocaleUrl } from 'groq/shared/LocaleUrl';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';

export type ProductListingPageContent = ProductListingPage & {
  bottomSeoContent?: any;
  localeBottomSeoContent?: Maybe<LocalePortableTextType>;
  seo: LocaleSeoType;
};

export type DefaultProductListingPageQuery = Pick<
  ProductListingPageContent,
  | '_id'
  | '_type'
  | 'recommenderEnabled'
  | 'recommenderId'
  | 'seo'
  | 'bottomSeoContent'
  | 'localeBottomSeoContent'
>;

export type TopContentSlotPLPContent = Pick<
  ProductListingPageContent,
  '_id' | '_type' | 'topContent'
>;

export type BottomContentSlotPLPContent = Pick<
  ProductListingPageContent,
  '_id' | '_type' | 'bottomContent'
>;

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    DefaultProductListingPage: ProductListingPageContent;
    TopContentSlotPLP: TopContentSlotPLPContent;
    BottomContentSlotPLP: BottomContentSlotPLPContent;
  }
}

export const DefaultProductListingPageQuery = `
  *[country == $country]
   [_type == "ProductListingPage"]
   [${QueryByLocaleUrl('category->')}]
   [0] {
    _id,
    _type,
    recommenderEnabled,
    recommenderId,
    "seo": category->${LocaleSeo('seo')},
    ${TileWithPositionObject('tiles[]')},
    bottomSeoContent[] {
      ...,
      markDefs[]{
        ...,
        "link": @ -> url
      }
    }, 
    ${LocalePortableText('localeBottomSeoContent')},
  }
`;

export const TopContentSlotPLPQuery = `
  *[country == $country]
   [_type == "ProductListingPage"]
   [${QueryByLocaleUrl('category->')}]
   [0] {
    _id,
    _type,
    topContent[] ${SlotsGroqQuery()},
  }
`;

export const BottomContentSlotPLPQuery = `
  *[country == $country]
   [_type == "ProductListingPage"]
   [${QueryByLocaleUrl('category->')}]
   [0] {
    _id,
    _type,
    bottomContent[] ${SlotsGroqQuery()},
  }
`;
